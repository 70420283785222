<template>
  <div>
    <PageDynamicBreadcrumb />
    <PageHeader title="Advertiser Account Editor" />

    <AdvertiserAccountForm
      type="edit"
      :advertiser-account-id="$route.params.advertiserAccountId"
      @submit="editAdvertiserAccount"
      :submitting="submitting"
    />
  </div>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader";
import AdvertiserAccountForm from "@/components/AdvertiserAccountForm";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import axios from "axios";

export default {
  name: "AdvertiserAccountEditor",
  components: {
    PageDynamicBreadcrumb,
    PageHeader,
    AdvertiserAccountForm
  },
  data() {
    return {
      submitting: false,
      account: []
    };
  },
  computed: {},
  mounted() {},
  methods: {
    editAdvertiserAccount(advertiserAccount) {
      this.submitting = true;
      axios
        .post("https://api-v2.jumpfeed.com/internal/advertiser-accounts/update", {
            id: advertiserAccount.id,
            bid_method: advertiserAccount.bid_method,
            static_bid_value: advertiserAccount.static_bid_value,
            bid_value_scrub_percentage:
              advertiserAccount.bid_value_scrub_percentage,
            lead_payout_value: advertiserAccount.lead_payout_value
        })
        .then(() => {
          this.triggerActionOutcomeAlert("update", true);
          this.$router.push({ path: "/manage/advertiser/accounts" });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("update", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
